import * as React from "react"
import { useRef } from "react"
import styled from "styled-components"
import { screenSizes } from "model"

const Container = styled.div`
  height: ${({ isOpen, height }) => (isOpen ? `${height}px` : "60px")};
  overflow: hidden;
  transition: height 0.5s;
  border-bottom: 1px solid #dddddd;

  @media (max-width: ${screenSizes.largeDesktop}px) {
    padding: 0 10px;
  }
`

const InnerContainer = styled.div``

interface PropsType {
  children: React.ReactElement[]
  isOpen?: boolean
  onClick?: () => void
}

const AccordionItem = ({ children, isOpen, onClick }: PropsType) => {
  const ref = useRef(null)

  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, {
      isOpen,
      onClick,
    })
  })

  return (
    <Container isOpen={isOpen} height={ref?.current?.offsetHeight || "auto"}>
      <InnerContainer ref={ref}>{childrenWithProps}</InnerContainer>
    </Container>
  )
}

export default AccordionItem
