import * as React from "react"
import styled from "styled-components"
import { screenSizes } from "model"

const Container = styled.div`
  padding-top: 0;
  padding-bottom: 10px;

  @media (max-width: ${screenSizes.desktop}px) {
    padding: 10px;
  }

  @media (max-width: ${screenSizes.smallMobile}px) {
    padding: 10px;
  }
`

const AccordionBody = ({ children }) => {
  return <Container>{children}</Container>
}

export default AccordionBody
