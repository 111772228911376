import * as React from "react"
import { FC, useContext } from "react"
import { LoginContext } from "components"
import Layout from "../social-supermarket/components/Layout"
import GuidePage from "../social-supermarket/pages/guide/GuidePage"
import SEO from "../social-supermarket/components/Seo"

interface Props {}

const Guide: FC<Props> = () => {
  const loginContext = useContext(LoginContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Help"} description={""} />
      <GuidePage />
    </Layout>
  ) : (
    <Layout />
  )
}

export default Guide
